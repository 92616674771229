import React from "react"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/Hero"

const Calendar = () => {
  return (
    <Layout>
      <SEO title="Calendar" />
      <Hero englishTitle={"Calendar"} syllabics={"ᐱᓯᒧᒪᓭᓀᐃᑲᐣ"}></Hero>

      {/* responsive iframe: https://blog.theodo.com/2018/01/responsive-iframes-css-trick/ */}
      {/* calendar container */}
      <div
        className="relative border-b border-gray-light"
        style={{ paddingTop: "53.82%", minHeight: "1000px" }}
      >
        <iframe
          className="absolute top-0 left-0"
          style={{ width: "100%", height: "100%", border: "0" }}
          src="https://outlook.live.com/owa/calendar/00000000-0000-0000-0000-000000000000/b137b2cc-449c-47e2-b99c-d7bbc830afec/cid-103739F01E70B9E5/index.html"
        />
      </div>
      {/* <NewsletterSignUp>Sign up For Calendar Updates</NewsletterSignUp> */}
    </Layout>
  )
}

export default Calendar
